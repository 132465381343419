.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.contactButton, .serviceButton, .video, .phone, .fax, .email, .health, .submit{
  border-style: solid;
  border-top-width: 2px;
  border-right-width: 2px;
  border-left-width: 2px;
  border-bottom-width: 2px;
  color: #ffffff;
  border-color: rgba(0,0,0,0);
  background-color: #68d4ff;
  border-radius: 0;
  padding-top: 16px;
  padding-right: 40px;
  padding-bottom: 16px;
  padding-left: 40px;
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.phone, .fax, .email {
  width: 300px
}

.video {
  width: 1080px;
  height: 50px;
}

.email {
  text-transform: none;
}

.submit {
  width: 100%;
  align-self: center;
}

.health {
  width: 100%;
  text-align: center;
}

.contactButton {
  margin: auto;
  align-items: flex-end;
}

.navbar, .footer{
  background-color:#dee8eb
}

.navbar-expand-sm {
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}

.contactDiv {
  margin: 1%;
}

.contact {
  margin: 1%, 1%, 1%, 1%;
}

.form-floating {
  margin: .5%;
}

.centered {
  flex: auto;
  justify-content: center;
}

.allergyDesc {
  font-size: 1.5em;
}

.serviceListLeft, .serviceListRight{ 
  font-size: 25px;
  margin-top: 30px;
  list-style-type: none;
  padding-left: 0;
}

.serviceListRight { 
  text-align: right;
}

li {
  padding: 10px;
}

h1.therapyTitle {
  font-size: 110px;
  vertical-align: bottom;
}

img.carosaulTherapyCenter {
  margin-left: "auto";
  margin-right: "auto"; 
  display: "flex";
  justify-content: "center";
}

